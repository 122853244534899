import React, { useEffect, useState } from 'react';
import * as styles from './ComplexTabs.module.css';
import clsx from 'clsx';
import GlobalWrapper from '../GlobalWrapper/GlobalWrapper';

export default function ComplexTabs({ className, id="tabs", children, openTab = 0 }) {
  
  const [tabVisible, setTabVisible] = useState(openTab);
  const [tabHead, setTabHead] = useState([]);
  const [tabContent, setTabContent] = useState([]);

  useEffect(() => {
    setTabVisible(openTab);
  }, [openTab]);

  useEffect(() => {
    let head = [];
    let tab = [];

    children.map((item, index) => {
      head.push(
        <button id={item.props['id'] || `tab-${index}`} key={ `tabH${index}` } className={ clsx(styles.tab, tabVisible === index && styles.selected) } onClick={() => tabChange_handler(index)}>
          <span className={styles.tabText}>
            { item.props['title'] }
          </span>
        </button>);
      tab.push(<div key={ `tabC${index}` } className={ clsx(styles.content, tabVisible === index && styles.open) }>{ item.props['children'] }</div>);
      return true;
    });

    setTabHead(head);
    setTabContent(tab);
  }, [children, tabVisible]);

  const tabChange_handler = (index) => {
    setTabVisible(index);
  };


  return (
    <div id={ id } className={ clsx(className) }>
      <GlobalWrapper showBorderLeft={true}>
        <div className={ styles.header}>
          { tabHead }
          <div className={ styles.yellowbar}></div>
        </div>
      </GlobalWrapper>
      { tabContent }
    </div>
  );
}
