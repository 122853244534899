import React from 'react';
import * as styles from './ComplexTabs.module.css';
import clsx from 'clsx';

export default function TabItem({ className, title, children}) {


  return (
    <div className={ clsx(className) }>
      <div className={ styles.header}>
        { title }
      </div>
      { children }
    </div>
  );
}